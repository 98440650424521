<script>
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators'

import store from '@state/store'

import Multiselect from 'vue-multiselect'

import UsersService from '@src/services/UsersService.js'

import Layout from '@layouts/main'
import PageHeader from '@/src/components/PageHeader.vue'
import googleAutoComplete from '@components/googleAutoComplete'
import validationMixin from '@src/mixins/validation'
import pagination from '@src/mixins/pagination'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import SaveButton from '@/src/components/SaveButton'
import TopicHeader from '@src/components/TopicHeader.vue'
import timezones from '@assets/resources/timezones'
import statesWithCities from '@src/assets/resources/statesWithCities'
import userFields from '@src/fields/userFields.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'PROFILE'

export default {
    components: {
        Layout,
        PageHeader,
        Multiselect,
        googleAutoComplete,
        SaveButton,
        TopicHeader,
    },
    mixins: [
        validationMixin,
        pagination,
        swalFeedbackVue,
        filterVuetable,
        userFields,
    ],
    data() {
        return {
            i18nCommon,
            i18nKey,
            componentKey: 0,
            filterRoleParameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            timezones,
            statesWithCities,
            statuses: [
                { value: true, text: this.getI18n(i18nCommon, 'active') },
                {
                    value: false,
                    text: this.getI18n(i18nCommon, 'not_active'),
                },
            ],
            modalHasResponseError: false,
            modalIsEdit: false,
            loading: false,
            userModal: {
                id: store.state.auth.currentUser.id,
                name: '',
                email: '',
                password: '',
                status: null,
                password_confirmation: '',
                timezone: null,
                address: null,
                role_id: null,
                city: {
                    id: null,
                    name: '',
                },
                state: {
                    id: null,
                    letter: '',
                    name: '',
                    slug: '',
                },
                role: {
                    id: null,
                    name: null,
                    alias: null,
                    parent_id: null,
                },
                latitude: null,
                longName: null,
                longitude: null,
                registeredNumber: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
                hasImage: false,
                profile_picture_id: null,
                profile_picture: null,
                profile_picture_url: null,
                image_new: null,
            },
            fields: [
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
            ],
            data: [],
            css: {},
            filterText: '',
            additionalParameters: {
                with: ['state', 'city'],
            },
            timezone: null,
            status: null,
            gModal: {
                address: null,
                city: {
                    id: null,
                    name: '',
                },
                state: {
                    id: null,
                    letter: '',
                    name: '',
                    slug: '',
                },
                latitude: null,
                longName: null,
                longitude: null,
                registeredNumber: null,
                complement: null,
                district: null,
                shortName: null,
                postalCode: null,
                number: null,
                placeId: null,
                humanReadableAddress: null,
            },
            submitLoading: false,
        }
    },
    validations: {
        userModal: {
            name: {
                required,
                minLength: minLength(2),
            },
            email: {
                required,
                email,
            },
            timezone: {
                required,
            },
            password: {
                minLength: minLength(6),
            },
            password_confirmation: {
                sameAsPassword: sameAs('password'),
            },
        },
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.i18nProfile,
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nEmail() {
            return this.getI18n(i18nCommon, 'email')
        },
        i18nTimezone() {
            return this.getI18n(i18nCommon, 'timezone')
        },
        i18nProfile() {
            return `${this.getI18n(i18nCommon, 'update')} ${this.getI18nModified({
                prefix: 'PROFILE.TITLES',
                suffix: 'profile',
                modifier: 2,
            })}`
        },
        i18nNewPassword() {
            return this.getI18n(i18nCommon, 'new_password')
        },
        i18nConfirmNewPassword() {
            return this.getI18n(i18nCommon, 'confirm_new_password')
        },
        i18nChangePassword() {
            return this.getI18n('TENANT', 'TITLES.change_password')
        },
    },
    mounted() {
        this.$nextTick(function () {
            this.fetch()
        })
    },
    methods: {
        changeTimezone(option) {
            this.$v.userModal.timezone.$model = option.id
            this.$v.userModal.timezone.$touch()
        },

        async fetch() {
            this.loading = true
            await UsersService.fetchById(this.userModal.id, this.additionalParameters)
                .then((response) => {
                    this.replaceUserModal(response.data.data)
                    this.loading = false
                })
                .catch((errors) => {
                    this.loading = false
                    return errors
                })
        },

        replaceUserModal(data) {
            Object.assign(this.userModal, data)
            Object.assign(this.gModal, data)
            this.componentKey += 1
            this.timezone = timezones.find(
                (timezone) => timezone.id === data.timezone
            )
        },

        handleOk() {
            this.$v.userModal.$touch()
            this.$refs.gautocomplete.$v.gModal.$touch()
            if (!this.$v.userModal.$invalid && !this.$refs.gautocomplete.$v.gModal.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },

        async handleSubmit() {
            const userInfo = {
                name: this.userModal.name,
                email: this.userModal.email,
                password: this.userModal.password,
                password_confirmation: this.userModal.password_confirmation,
                timezone: this.userModal.timezone,
                costs: this.userModal.costs,
                lat: this.$refs.gautocomplete.gModal.latitude,
                route: this.$refs.gautocomplete.gModal.route,
                number: this.$refs.gautocomplete.gModal.number,
                long: this.$refs.gautocomplete.gModal.longitude,
                address: this.$refs.gautocomplete.gModal.address,
                long_name: this.$refs.gautocomplete.gModal.longName,
                short_name: this.$refs.gautocomplete.gModal.shortName,
                district: this.$refs.gautocomplete.gModal.district,
                place_id: this.$refs.gautocomplete.gModal.placeId,
                zip_code: this.$refs.gautocomplete.gModal.postalCode,
                complement: this.$refs.gautocomplete.gModal.complement,
                city_id: this.$refs.gautocomplete.gModal.city_id ?? null,
                city: this.$refs.gautocomplete.gModal.city ? this.$refs.gautocomplete.gModal.city.label : null,
                state: this.$refs.gautocomplete.gModal.state ? this.$refs.gautocomplete.gModal.state.label : null,
            }

            if (!userInfo.password) {
                delete userInfo.password
                delete userInfo.password_confirmation
            }

            const operation = UsersService.update(this.userModal.id, userInfo)

            const response = await operation
                .then((response) => response)
                .catch((errors) => errors)

            const statusCode = response.status.toString()
            if (statusCode.charAt(0) === '2') {
                this.$store.dispatch('auth/updateUser', {
                    user: response.data.data,
                })

                this.userModal.password = ''
                this.userModal.password_confirmation = ''

                this.positiveFeedback(this.i18nProfile, 'edited')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nProfile, response.data.errors)
            }

            this.submitLoading = false
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <div class="card card-pdv mt-3">
                <PageHeader :title="i18nProfile" />
                <form>
                    <topic-header
                        :caption="
                            getI18nModified({
                                prefix: i18nCommon,
                                suffix: 'profile',
                                modifier: 2,
                            })"
                        icon="fe-user"
                    />
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nName"
                                label-for="userModal-name"
                                class="required label-pdv"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                            >
                                <b-form-input
                                    id="userModal-name"
                                    v-model.trim="$v.userModal.name.$model"
                                    class="input-pdv-blue"
                                    name="name"
                                    aria-describedby="input-1-live-feedback"
                                    type="text"
                                    :state="validateField('name', 'userModal')"
                                    :placeholder="i18nName"
                                    :disabled="loading || submitLoading"
                                    @blur="$v.userModal.name.$touch"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                :label="i18nEmail"
                                label-for="userModal-email"
                                class="required label-pdv"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_email')"
                            >
                                <b-form-input
                                    id="userModal-email"
                                    v-model.trim="$v.userModal.email.$model"
                                    class="input-pdv-blue"
                                    name="email"
                                    aria-describedby="input-1-live-feedback"
                                    type="email"
                                    :state="validateField('email', 'userModal')"
                                    :placeholder="i18nEmail"
                                    disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                :label="i18nTimezone"
                                class="required label-pdv"
                                label-for="userModal-timezone"
                            >
                                <multiselect
                                    id="userModal-timezone"
                                    v-model="timezone"
                                    :options="timezones"
                                    track-by="id"
                                    label="label"
                                    aria-describedby="input-1-live-feedback"
                                    :class="validationClass($v.userModal.timezone)"
                                    :disabled="loading || submitLoading"
                                    @input="changeTimezone"
                                ></multiselect>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <topic-header
                        :caption="
                            getI18nModified({
                                prefix: i18nCommon,
                                suffix: 'address',
                                modifier: 1,
                            })"
                        icon="fe-map-pin"
                    />
                    <google-auto-complete
                        id="autocomplete_address"
                        ref="gautocomplete"
                        :key="componentKey"
                        :modal="gModal"
                        :required="false"
                        :disabled="loading || submitLoading"
                    />
                    <topic-header
                        :caption="i18nChangePassword"
                        icon="fe-unlock"
                    />
                    <b-row>
                        <b-col md="4">
                            <b-form-group
                                class="label-pdv"
                                :label="i18nNewPassword"
                                label-for="modal-password"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_password_length')"
                            >
                                <b-form-input
                                    id="modal-password"
                                    v-model.trim="$v.userModal.password.$model"
                                    class="input-pdv-blue"
                                    name="password"
                                    type="password"
                                    autocomplete="new-password"
                                    aria-describedby="input-1-live-feedback"
                                    :placeholder="getI18n('PLACEHOLDERS.password_length')"
                                    :state="validateField('password', 'userModal')"
                                    :disabled="loading || submitLoading"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                                class="label-pdv"
                                :label="i18nConfirmNewPassword"
                                label-for="modal-password-confirmation"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_password_match')"
                            >
                                <b-form-input
                                    id="modal-password-confirmation"
                                    v-model.trim="$v.userModal.password_confirmation.$model"
                                    name="password_confirmation"
                                    class="input-pdv-blue"
                                    type="password"
                                    autocomplete="new-password"
                                    aria-describedby="input-1-live-feedback"
                                    :placeholder="i18nConfirmNewPassword"
                                    :state="validateField('password_confirmation', 'userModal')"
                                    :disabled="loading || submitLoading"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-container>
                        <div class="d-flex justify-content-center mt-2">
                            <save-button
                                ref="okButton"
                                child-class="col-sm-6"
                                :busy="submitLoading"
                                :disabled="loading"
                                @onClick="handleOk"
                            />
                        </div>
                    </b-container>
                </form>
            </div>
        </div>
    </Layout>
</template>
