<template>
    <div>
        <div class="d-flex">
            <h5>
                <i v-if="icon" :class="`${icon} mr-1`" />
                {{ caption }}
            </h5>
            <p v-if="subcaption" style="margin: auto 5px">
                ({{ subcaption }})
            </p>
        </div>
        <hr class="mt-0">
    </div>
</template>

<script>
export default {
    props: {
        caption: {
            type: String,
            required: true,
        },
        subcaption: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: false,
            default: '',
        },
    },
}
</script>
