import BaseService from '@src/services/BaseService'

class UsersService extends BaseService {
    constructor() {
        super('users')
    }

    deleteMultiple(params) {
        return this.axios.post(`${this.getUrl()}/delete-multiple`, params)
    }
}

export default new UsersService()
