<script>
import VuetableFieldCheckbox from '@src/components/vuetable/VuetableFieldCheckbox.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    mixins: [vuetableFormatter],
    data() {
        return {
            userFields: [
                {
                    name: VuetableFieldCheckbox,
                    titleClass: 'center aligned',
                    dataClass: 'center aligned',
                    togglable: true,
                    disabled: function(rowData) {
                        if (rowData && rowData.role.length > 0) {
                            return rowData.role[0].name === 'admin entidade'
                        }
                        return false
                    }
                },
                {
                    name: 'name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'name',
                },
                {
                    name: 'email',
                    title: this.getI18n(i18nCommon, 'email'),
                    sortField: 'email',
                },
                {
                    name: 'role.0.alias',
                    title: this.getI18n(i18nCommon, 'role'),
                },
                {
                    name: 'workday.name',
                    title: this.getI18n(i18nCommon, 'workday'),
                },
                {
                    name: 'long_name',
                    title: this.getI18n(i18nCommon, 'address'),
                    sortField: 'address',
                },
                {
                    name: 'timezone',
                    title: this.getI18n(i18nCommon, 'timezone'),
                    sortField: 'timezone',
                    formatter: this.formatTimezone,
                },
                {
                    name: 'app_version',
                    title: this.getI18n(i18nCommon, 'app_version'),
                    sortField: 'app_version',
                },
                {
                    name: 'platform',
                    title: this.getI18n(i18nCommon, 'operational_system'),
                    sortField: 'platform',
                    formatter: (value) => {
                        return this.formatPlatform(value)
                    },
                },
                {
                    name: 'active',
                    title: this.getI18n(i18nCommon, 'active'),
                    sortField: 'active',
                    formatter: (value) => {
                        return this.booleanIcons(value)
                    },
                },
                {
                    name: 'status',
                    title: this.getI18n(i18nCommon, 'status'),
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'updated_at',
                    title: this.getI18n(i18nCommon, 'updated_at'),
                    sortField: 'updated_at',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
        }
    },
}
</script>
